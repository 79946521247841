const messages = {
  taskTitle: 'Task Management Center',
  recentTasks: 'Recent Tasks',
  displayTip: 'Only show recent 10 tasks: ',
  deleteConfirmTitle: 'Delete Confirm',
  deleteConfirmContent: 'Are you sure you want to delete this task?',
  runTime: 'Run Time',
  id: 'Task ID',
  name: 'Task Name',
  cancel: 'Cancel',
  status: 'Status',
  action: 'Action',
  check: 'Check',
  delete: 'Delete',
  download: 'Download',
  complete: 'Completed',
  running: 'Running',
  error: 'Error',
  module: 'Module',
  taskStatus: 'Status',
  createTime: 'Create Time',
  result: 'Filter',
  showAll: 'Show All',
  twentyFourHours: '24 Hours',
  fiveDays: 'Five Days',
  taskSuccess: 'is finished',
  taskFailed: 'is failed'
}

export default messages
