const messages = {
  choose: 'Please choose data',
  upload: 'Please upload your file',
  method: 'Please choose one method',
  config: 'Please choose one config file',
  dataInput: 'Input Data',
  dataChoose: 'Choose Data',
  localUpload: 'Upload from local',
  configChoose: 'Choose config',
  configUpload: 'Upload config from local',
  dataFilter: 'Filter Data',
  paramsInput: 'Input Params',
  methodChoose: 'Choose Method',
  processData: 'Data Processing',
  params: {
    checkParams: 'Please input parameter',
    selectParams: 'Please select parameter(s)',
    checkPattern: 'Only allow number, letters and underline',
    checkListPattern: 'Only allow number, letters, underline, space and comma',
    checkNumberPattern: 'Only allow number, split by space and comma',
    checkRange: 'Range: {min}-{max}',
    preProcessing: {
      checkMinGenes: 'Range: 0-30000',
      checkMaxGenes: 'Range: 0-90000',
      checkNormCount: 'Range: 100-100000',
      checkNTop: 'Range: 100-20000',
      checkMinMean: 'Range: 0.0001-1',
      checkMaxMean: 'Range: 1-100',
      checkNumberOfNeighbors: 'Range: 1-10000',
      checkNComponents: 'Range: 1-1000',
      checkMaxValue: 'Range: 1-1000'
    },
    placeholder: {
      clusterName: 'Please input a name of clustering result from "obs" property'
    }
  }
}
export default messages
