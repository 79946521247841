const messages = {
  home: 'Home',
  showAll: 'Show All',
  ConvertFastq: 'FastQ Converter',
  dataProcessing: 'Preprocessing',
  DataIntegration: 'Data Merge',
  PreProcessing: 'Pre-processing',
  DownstreamAnalysis: 'Downstream Analysis',
  Clustering: 'Clustering',
  DifferentialExpression: 'Differential Expression',
  CellTypeAnnotation: 'Cell Type Annotation',
  GeneSetEnrichment: 'Gene Set Enrichment Analysis',
  TrajectoryAnalysis: 'Trajectory Analysis',
  CellCommunication: 'Cell–Cell Communication',
  InferCNV: 'Infer CNV',
  AdvancedDownstreamAnalysis: 'Advanced Downstream Analysis',
  MolecularSubtyping: 'Molecular Subtyping',
  KnowledgeGraph: 'Knowledge Graph',
  SpatialTranscriptomics: 'Spatial Transcriptomics'
}

export default messages
