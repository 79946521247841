const messages = {
  loginOptionSelect: `Log in to USAIL<sup>scRNA</sup>`,
  qqLogin: 'QQ login',
  wechatLogin: 'Log in with your WeChat',
  mailLogin: 'Log in with your email',
  noAccount: 'Don’t have an account?',
  registerNow: 'Register Now',
  mailInputTip: 'Please input your Mail',
  passwordInputTip: 'Please input your password',
  captchaInputTip: 'Please input the captcha',
  mailEmptyError: 'Mail cannot be empty',
  mailNotCorrect: 'Mail not correct',
  usernameEmptyError: 'Username cannot be empty',
  passwordEmptyError: 'Password cannot be empty',
  passwordNotMatch: 'Password not match',
  captchaEmptyError: 'Captcha cannot be empty',
  captchaNotCorrect: 'Captcha not correct',
  captchaUpdateTip: 'Click to change the captcha',
  loginBtnText: 'Login',
  otherLoginOption: 'Change other login options',
  forgetPassword: 'Forget password',
  loginConfirm: 'Login Confirm',
  loginConfirmMsg: 'Not login or session expires, go to the login page',
  mailRegister: 'Register By Email',
  confirmPassword: 'Input confirm password',
  registerBtnText: 'Agree and Submit',
  usernameInputTip: 'Please input username',
  term: 'I have read and agree {tos}',
  tos: 'Disclaimer',
  recoverPassword: 'Recover Password',
  confirm: 'Confirm',
  backToHome: 'Back to Home',
  resetLinkSendSuccessTips: 'A password reset link has been sent to your email, please follow the instruction to reset your password.',
  resetPassword: 'Reset your Password',
  resetSuccess: 'Reset Success',
  resetSuccessTips: 'Your password has been reset successfully, Please login.',
  register: {
    successTitle: 'Congrats! Register success',
    activateTips: 'An activation link has been sent to your email, please follow the instruction to activate your account.',
    verifyNow: 'Verify Now',
    specify: 'Please specify',
    industryEmptyTips: 'Please specify your industry',
    organizationInputTips: 'Organization/Company/School',
    careerInputTips: 'Please input your career info',
    industryEmptyError: 'Industry cannot be empty',
    organizationEmptyError: 'Organization cannot be empty',
    careerEmptyError: 'Career cannot be empty',
    acceptEmailTips: 'Accepting email about questionnaire, algorithm update or news',
    acceptEmailPrompt: 'Do you want to accept email about questionnaire, algorithm update or news ?',
    mailSelectWarning: 'To make your registration success, we strongly recommend you to use an organizational or educational email address'
  }
}

export default messages
