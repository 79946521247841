import RequestMessage from './request'
import NavMessage from './nav'
import TaskMessage from './task'
import BrowserMessage from '../../common/en/browser'
import CommonMessage from '../../common/en/common'
import ModulesMessage from '../../common/en/modules'
import FormMessage from './form'
import LoginRegisterMessage from '../../common/en/login_register'
// @ts-ignore
import elementEnLocale from 'element-ui/lib/locale/lang/en'

const messages = {
  common: CommonMessage,
  request: RequestMessage,
  nav: NavMessage,
  browser: BrowserMessage,
  modules: ModulesMessage,
  task: TaskMessage,
  form: FormMessage,
  loginRegister: LoginRegisterMessage,
  ...elementEnLocale
}

export default messages
